/**
*
* MARIANNE (titres)
*
*/

@font-face {
  font-family: "Marianne";
  src:
    local("Marianne"),
    url("../fonts/Marianne/Marianne-Bold.woff") format("woff"),
    url("../fonts/Marianne/Marianne-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "Marianne";
  src:
    local("Marianne"),
    url("../fonts/Marianne/Marianne-Medium.woff") format("woff"),
    url("../fonts/Marianne/Marianne-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Marianne";
  src:
    local("Marianne"),
    url("../fonts/Marianne/Marianne-Regular.woff") format("woff"),
    url("../fonts/Marianne/Marianne-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}
